import React, { useState, useEffect } from 'react';
import Header from '../../Components/Headers/Header';
import { getHeaders, redirectToThankYouPage } from '../../utils/apiUtils';
import { useLocation } from 'react-router-dom';

const DesktopCsatNextLevel = () => {
  const fontFamily = sessionStorage.getItem('fetchedFontFamily') || 'Arial'; // Use fallback if fontFamily is null  
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);
  const [tokenEnabled, setTokenEnabled] = useState(true);
  const [uniqueToken, setUniqueToken] = useState(null);
  const [isVoncierge, setIsVoncierge] = useState(false);
  const location = useLocation();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    setUniqueToken(token);
  }, [location]);
  const maxLength = 127;
  const [comment, setComment] = useState('');

  const primaryColor = sessionStorage.getItem('primaryColor')
  const buttonStyles = {
    borderColor: primaryColor,
    color: primaryColor
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.backgroundColor = primaryColor;
    e.currentTarget.style.color = 'white';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.backgroundColor = '';
    e.currentTarget.style.color = '#808285';
  };
  // const 
  const handleCallClick = () => {
    // fetchLogoutApi();
    sessionStorage.removeItem('selectedLanguage')
    sessionStorage.removeItem('selectedCat');
    sessionStorage.removeItem('call_id')
    sessionStorage.removeItem('temp_CallId')
    sessionStorage.removeItem('user_id')
    sessionStorage.removeItem('chatMessages')
    sessionStorage.removeItem('advisor_name')
    sessionStorage.removeItem('advisor_id')
    sessionStorage.removeItem('advisor_img')
    window.location.replace('/languages');
  }
  useEffect(() => {
    if (!isSubmitDisabled) {
      document.body.classList.add("submitDisabled");
    } else {
      document.body.classList.remove("submitDisabled");
    }
  }, [isSubmitDisabled]);
  useEffect(() => {

    const questionDetails = sessionStorage.getItem('questionDetails');
    console.log('questionDetails', questionDetails)
    if (questionDetails) {
      try {
        const parsedData = JSON.parse(questionDetails);

        if (Array.isArray(parsedData)) {
          setQuestions(parsedData);
          if (parsedData.length > 1) {
            setIsNextBtnDisabled(false);
            setIsSubmitDisabled(true);
          } else {
            setIsNextBtnDisabled(true);
            setIsSubmitDisabled(false);

          }
        }
      } catch (error) {
        console.error('Error parsing question details:', error);
      }
    }
  }, []);
  const handleChange = (e) => {
    const value = e.target.value;
    setComment(value);

    setAnswers((prev) => ({
      ...prev,
      [questions[currentQuestionIndex].id]: value,
    }));

    // setIsSubmitDisabled(true);
  };
  const handleRadioChange = (questionId, option) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: {
        id: option.id,
        text: option.text,
        image: option.image,
      },
    }));
    // setIsSubmitDisabled(true);
  };


  const handleCheckboxChange = (questionId, option, checked) => {
    setAnswers((prevAnswers) => {
      const currentOptions = prevAnswers[questionId] || [];

      let updatedOptions;
      if (checked) {
        updatedOptions = [...currentOptions, {
          id: option.id,
          text: option.text,
          image: option.image
        }];
      } else {
        updatedOptions = currentOptions.filter((o) => o.id !== option.id);
      }

      return {
        ...prevAnswers,
        [questionId]: updatedOptions,
      };
    });

    // setIsSubmitDisabled(true);
  };


  const handleSubmit = async () => {
    const callId = sessionStorage.getItem('call_id');

    for (const question of questions) {
      const answer = answers[question.id] || [];
      let optionId = [];
      let optionText = [];
      let optionImage = null;

      if (question.question_type == 'checkbox' && Array.isArray(answer)) {
        // Ensure `option_id` is always an array for checkboxes
        if (Array.isArray(answer)) {
          optionId = [answer.map(opt => opt.id)];
          optionText = [answer.map(opt => opt.text).join(", ")];
          optionImage = answer.map(opt => opt.image).join(", ");
        }
      } else if (question.question_type == 'radio' && answer) {
        optionId = [answer.id];
        optionText = [answer.text] || null;
        optionImage = answer.image || null;
      }

      const payload = {
        question_id: question.id,
        question_type: question.question_type,
        question_text: question.question || "",
        ...(question.question_type == 'input' && {
          answer: answer, // Mandatory for input-type questions
        }),
        ...(question.question_type == 'radio' || question.question_type == 'checkbox' ? {
          option_id: optionId, // Ensure array format
          option_text: optionText,
          image: optionImage, // Mandatory for radio/checkbox
        } : {}),
      };
      console.log('payload', payload)


      try {
        const url = `${process.env.REACT_APP_BASE_URL_API}review/response/${callId}`;
        const response = await fetch(url, {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          if (currentQuestionIndex < questions.length - 1) {
            const newIndex = currentQuestionIndex + 1;
            setCurrentQuestionIndex(newIndex);
            sessionStorage.setItem('currentQuestionIndex', newIndex);
          } else {
            redirectToThankYouPage();
          }
        }
      } catch (error) {
        console.error('Error submitting responses:', error);
      }
    }
  };

  useEffect(() => {
    const vonciergeStatus = sessionStorage.getItem('isVoncierge');
    setIsVoncierge(vonciergeStatus === '1');

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (token) {
      setTokenEnabled(false)
    }
    const storedIndex = sessionStorage.getItem('currentQuestionIndex');
    if (storedIndex !== null) {
      setCurrentQuestionIndex(parseInt(storedIndex, 10));
    }
  }, [])

  useEffect(() => {
    if (!questions.length) return;

    const currentQuestion = questions[currentQuestionIndex];

    if (!currentQuestion) {
      setIsSubmitDisabled(true);
      return;
    }

    const currentAnswer = answers[currentQuestion.id];

    let isDisabled = true;

    if (currentQuestion.question_type === 'input') {
      isDisabled = !currentAnswer || currentAnswer.trim() === '';
    } else if (currentQuestion.question_type === 'radio') {
      isDisabled = !currentAnswer || !currentAnswer.id;
    } else if (currentQuestion.question_type === 'checkbox') {
      isDisabled = !currentAnswer || currentAnswer.length === 0;
    }

    setIsSubmitDisabled(isDisabled);
  }, [answers, currentQuestionIndex, questions]);


  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div style={{ fontFamily }}>
      <Header showHomeButton={!uniqueToken} />
      <div className="flex flex-col text-center mt-[20px] sm:mt-[50px] sm:px-[100px] px-[30px] w-full dynamicCsat">
        {currentQuestion ? (  // Ensure a question exists before rendering
          <div className="question-container">
            <h2 className="mt-[10px] text-[#5D5145] text-[36px] font-bold leading-normal sm:leading-[43px] subheading">
              {currentQuestion.question}
            </h2>
            <div className="flex justify-center flex-wrap gap-20 mt-[20px] mb-[100px]">
              {currentQuestion.question_type == 'checkbox' ? (
                <div className="w-full">
                  <p className='mb-[20px] sm:mb-[60px] text-[#5D5145]'>Select all that applies</p>
                  <div className="flex items-center flex-wrap justify-center gap-10 w-full">
                    {currentQuestion.options.map((option, index) => {
                      const isChecked = answers[currentQuestion.id]?.some(o => o.id === option.id);

                      return (
                        <div key={index}
                          className={`cursor-pointer rounded-[24px] sm:rounded-[34px] p-4 flex items-center justify-center w-full sm:w-[40%] xl:w-[25%] csatBox h-[232px] d-flex flex-col relative
                              ${isChecked ? 'border-4' : 'border'}`}
                          style={{ borderColor: isChecked ? primaryColor : primaryColor }}>
                          <div className='w-[82px] h-[82px] mx-auto'>
                            <img src={option.image} className='w-full h-full object-contain' />
                          </div>
                          <input value=""
                            className="opacity-0 absolute w-full h-full cursor-pointer"
                            type="checkbox"
                            checked={isChecked}
                            onChange={(e) => handleCheckboxChange(currentQuestion.id, option, e.target.checked)}
                          />
                          <span className='text-OyesterGrey text-[20px] mt-[20px]'>{option.text}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : currentQuestion.question_type == 'radio' ? (
                <div className='w-full'>
                  <p className='mb-[60px] text-[#5D5145]'>Select any one that applies</p>
                  <div className="flex items-center justify-center flex-wrap gap-10">
                    {currentQuestion.options.map((option, index) => {
                      const isSelected = answers[currentQuestion.id]?.id === option.id;

                      return (
                        <div key={index}
                          className={`cursor-pointer rounded-[24px] sm::rounded-[34px] p-4 flex items-center justify-center w-full sm:w-[40%] csatBox h-[232px] relative d-flex flex-col
                                ${isSelected ? 'border-[#EA4498] border-4' : 'border-[#EA449880] border-2'}`}>
                          <img src={option.image} />
                          <input className='opacity-0 absolute w-full h-full cursor-pointer' value=""
                            type="radio"
                            checked={isSelected}
                            onChange={() => handleRadioChange(currentQuestion.id, option)}
                          />
                          <span className='break-words inline-block mt-[20px]' style={{ wordBreak: 'break-word' }}>{option.text}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : currentQuestion.question_type == 'input' ? (
                <div className='w-full'>
                  <div className='border-2 border-[#7D6A551A] rounded-[15px] text-left p-2 mt-10'>
                    <p className='text-OyesterGrey text-[16px] font-bold'>Additional comments (optional)</p>
                    <textarea name="" id="" value={comment ?? ''} className='text-[20px] mt-1 w-full outline-none h-[90px] text-OyesterGrey' placeholder='Enter comments' maxLength={maxLength} onChange={handleChange}></textarea>
                    <p className='mt-2 text-right text-OyesterGrey text-[16px]'>{`${maxLength - comment.length} Characters remaining`}</p>
                  </div>
                </div>
              ) : null}
            </div>
            <div className='mt-[40px] btnGroup fixed bottom-0 bg-white py-4 w-full left-0 shadow-[0px_-3px_30px_0px_#0000001A]'>
              <div className='flex justify-center gap-10'>
                {tokenEnabled &&
                  <div className='startNewCallBtn' onClick={handleCallClick}>
                    <button className='border-2 text-fuchsia border-fuchsia text-[16px] font-bold uppercase rounded-[12px] w-[280px] sm:w-[310px] sm:h-[50px] h-[56px] transition-all px-[20px]' style={buttonStyles} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                      Start another call
                    </button>
                  </div>
                }
                <div>
                  <button className='text-white w-[280px] sm:w-[310px] sm:h-[50px] h-[56px] transition-all rounded-[12px] px-[20px] font-bold bgSubmit'
                    onClick={handleSubmit} // Example trigger
                    disabled={isSubmitDisabled}
                    style={{
                      backgroundColor: isSubmitDisabled ? "#ddd" : primaryColor,
                      cursor: isSubmitDisabled ? "not-allowed" : "pointer"
                    }}
                  >
                    Submit
                  </button>
                  {/* <button className={`text-white w-[280px] sm:w-[310px] sm:h-[50px] h-[56px] transition-all rounded-[12px] px-[20px] font-bold bgSubmit 
                    ${!isSubmitDisabled ? `pointer-events-auto text-white` : 'bg-gray-300 pointer-events-none'}`} onClick={handleSubmit} disabled={isSubmitDisabled} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>SUBMIT</button>  */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>Loading questions...</p>
        )}
      </div>
    </div>
  );
};

export default DesktopCsatNextLevel;
