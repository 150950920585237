  import React, { useState, useEffect } from 'react';
  import LogoLight from '../LogoLight';
  import { useLocation, useNavigate } from 'react-router-dom';
  import { useTranslation } from 'react-i18next';

  const Header = ({ showBackButton, showHomeButton, handleClickGoBack, langCode, handleGoHome }) => { 
    const headerColor = sessionStorage.getItem('headerColor')

    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1025);
   
    const nav = useNavigate();
    const handleGoBack = () => {
      handleClickGoBack();
    };

    const handleNavigateToHome = () => {
      if (handleGoHome) {
        handleGoHome();
      } else {
        nav(`/${langCode ? langCode : ''}`);
      }
    };
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 1025);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
      <>
        {isMobile ? (
          <div className='w-full h-[60px] sm:h-[100px] relative header' style={{backgroundColor : `${headerColor}`}}>
          {showBackButton && (
            <div className='cursor-pointer absolute left-[35px] sm:left-[2rem] xl:left-20 headerBtn headerBackBtn top-[50%]' onClick={handleGoBack}>            
                
                <div className='changiHeaderIcon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="43" viewBox="0 0 42 43" fill="none">
                    <g clipPath="url(#clip0_7986_2977)">
                    <path opacity="0.8" d="M21.022 42.1807C9.41654 42.088 0.017928 32.9824 2.58807e-05 21.1913C-0.0178762 9.51565 9.25379 0.162797 21.0497 0.18069C32.6665 0.198582 41.9479 9.41805 42 21.0741C42.0521 32.7953 32.7593 42.0538 21.0236 42.1807H21.022Z" fill="white"/>
                    <path d="M18.1297 20.8554C20.2926 22.9992 22.3188 24.9788 24.3027 26.999C24.6493 27.352 24.9407 27.8464 25.0562 28.323C25.2482 29.1152 24.7649 29.9203 24.0537 30.2717C23.2676 30.6588 22.4995 30.5498 21.8306 29.8894C19.9329 28.014 18.05 26.1206 16.1621 24.2354C15.5209 23.5962 14.8715 22.9634 14.2433 22.3128C13.4084 21.4458 13.3954 20.3739 14.2417 19.5216C16.7431 17.002 19.2559 14.4938 21.7736 11.9905C22.6166 11.1528 23.7266 11.1252 24.5159 11.8799C25.3052 12.6346 25.3085 13.8318 24.4687 14.6841C22.6801 16.501 20.8704 18.2951 19.0606 20.0909C18.8084 20.3414 18.5105 20.5463 18.1297 20.8586V20.8554Z" fill="#6B2A7E"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_7986_2977">
                    <rect width="42" height="42" fill="white" transform="translate(0 0.180664)"/>
                    </clipPath>
                    </defs>
                    </svg>
                </div>
                <div className='vonciergeHeaderIcon'>
                    <svg width="64" height="65" viewBox="0 0 64 65" fill="none">
                    <mask id="mask0_7985_3376" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="65">
                    <path d="M7.05654 6.569C0.919143 11.9395 -0.188999 26.2823 0.0241046 32.7824C-0.189003 40.3481 0.983067 55.1597 8.0155 59.6352C15.0479 64.1106 26.449 64.6434 32.3094 64.75C38.7025 64.75 46.0546 64.75 55.6443 59.9549C62.3571 55.1597 63.6357 45.8891 63.9553 32.7824C64.275 19.6757 62.9964 10.7248 56.2836 5.60999C50.9443 1.54169 37.9566 0.601724 32.3094 0.814834C26.449 0.495158 13.1939 1.19845 7.05654 6.569Z" fill="#fff"/>
                    </mask>
                    <g mask="url(#mask0_7985_3376)">
                    <rect width="107.52" height="107.52" transform="translate(-21.7598 -21.0098)" fill="white"/>
                    <rect x="-21.7598" y="-21.0098" width="107.52" height="107.52" fill="white"/>
                    <g clipPath="url(#clip0_7985_3376)">
                    <path d="M34.853 51.8609L17.8891 34.897C17.3198 34.3276 17 33.5553 17 32.75C17 31.9447 17.3198 31.1724 17.8891 30.603L34.853 13.6391C35.2777 13.2146 35.8187 12.9254 36.4077 12.8083C36.9967 12.6912 37.6072 12.7513 38.1621 12.9811C38.7169 13.2109 39.1911 13.6001 39.5248 14.0994C39.8585 14.5986 40.0367 15.1857 40.0368 15.7862V49.7138C40.0367 50.3143 39.8585 50.9014 39.5248 51.4006C39.1911 51.8999 38.7169 52.2891 38.1621 52.5189C37.6072 52.7487 36.9967 52.8088 36.4077 52.6917C35.8187 52.5746 35.2777 52.2854 34.853 51.8609Z" fill={headerColor} />
                    </g>
                    </g>
                    <defs>
                    <clipPath id="clip0_7985_3376">
                    <rect width="40" height="40" fill="white" transform="translate(12 12.75)"/>
                    </clipPath>
                    </defs>
                    </svg>
                </div>
            </div>
          )}
          <div className='h-full logoSection'>
            <LogoLight />
          </div>
          {showHomeButton && (
            <div className='cursor-pointer absolute sm:right-15 right-[40px] sm:right-[2rem] w-auto xl:right-20 headerBtn homeButton top-[50%]' onClick={handleNavigateToHome}>            
                <div className='changiHeaderIcon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="43" viewBox="0 0 42 43" fill="none">
                    <g clipPath="url(#clip0_7986_3035)">
                    <g opacity="0.8">
                    <path d="M21.0269 0.180665C32.6403 0.183284 42.0079 9.6032 42 21.273C41.9922 32.8275 32.5461 42.1898 20.9077 42.1807C9.32825 42.1715 -0.0130822 32.7175 1.37529e-05 21.0216C0.0131097 9.55081 9.46969 0.178045 21.0269 0.180665ZM11.2717 21.8256C11.2717 22.1517 11.2717 22.3888 11.2717 22.6245C11.2717 25.228 11.2691 27.8301 11.273 30.4336C11.2743 31.6424 11.9108 32.2958 13.1221 32.3129C14.2379 32.3286 15.3537 32.3168 16.4695 32.3168C16.8768 32.3168 17.284 32.3168 17.7424 32.3168C17.7424 31.9855 17.7424 31.7471 17.7424 31.5088C17.7424 29.9124 17.7188 28.3147 17.7489 26.7183C17.7856 24.7683 19.1358 23.4063 20.9823 23.4011C22.8367 23.3958 24.1895 24.7369 24.2524 26.6816C24.2838 27.6429 24.2589 28.6067 24.2589 29.5693C24.2589 30.4598 24.2589 31.3516 24.2589 32.2814C25.9889 32.2814 27.6469 32.3391 29.297 32.2592C30.2189 32.2147 30.7231 31.5009 30.7257 30.5358C30.731 27.8891 30.7283 25.2424 30.7283 22.5957C30.7283 22.3613 30.7283 22.1269 30.7283 21.8675C30.9706 21.8518 31.1395 21.8348 31.3098 21.8309C32.364 21.8073 32.9311 21.4537 33.2061 20.6444C33.4719 19.8626 33.2637 19.3112 32.4295 18.6119C29.3821 16.0556 26.3333 13.4992 23.2833 10.9442C21.848 9.74201 20.135 9.75249 18.6932 10.9639C17.4556 12.005 16.2128 13.0409 14.9739 14.0794C13.0986 15.6535 11.218 17.2211 9.35183 18.8057C8.79918 19.2746 8.52154 19.8586 8.78346 20.609C9.04276 21.3542 9.57315 21.7706 10.3916 21.823C10.6523 21.8387 10.9142 21.8256 11.2717 21.8256Z" fill="white"/>
                    <path d="M11.2719 21.8254C10.9143 21.8254 10.6524 21.8398 10.3918 21.8228C9.57331 21.7717 9.04293 21.3553 8.78363 20.6088C8.52171 19.8584 8.80065 19.2743 9.35199 18.8055C11.2182 17.2209 13.0987 15.652 14.9741 14.0792C16.213 13.0394 17.4558 12.0035 18.6933 10.9637C20.1352 9.75228 21.8481 9.74181 23.2835 10.944C26.3335 13.499 29.3822 16.0554 32.4297 18.6117C33.2639 19.311 33.4721 19.8624 33.2063 20.6442C32.9312 21.4548 32.3655 21.8071 31.31 21.8307C31.1397 21.8346 30.9695 21.8516 30.7285 21.8673C30.7285 22.1279 30.7285 22.3611 30.7285 22.5955C30.7285 25.2422 30.7324 27.8889 30.7259 30.5355C30.7233 31.4994 30.2191 32.2131 29.2971 32.259C27.6457 32.3402 25.9878 32.2812 24.2591 32.2812C24.2591 31.3514 24.2591 30.4609 24.2591 29.5691C24.2591 28.6065 24.284 27.6427 24.2526 26.6814C24.1897 24.7367 22.8382 23.3956 20.9825 23.4009C19.136 23.4061 17.7858 24.7681 17.7491 26.7181C17.719 28.3145 17.7426 29.9122 17.7426 31.5086C17.7426 31.7469 17.7426 31.984 17.7426 32.3166C17.2842 32.3166 16.8769 32.3166 16.4696 32.3166C15.3539 32.3166 14.2381 32.3284 13.1223 32.3127C11.9109 32.2956 11.2745 31.6435 11.2732 30.4334C11.2692 27.8299 11.2719 25.2278 11.2719 22.6243C11.2719 22.3872 11.2719 22.1515 11.2719 21.8254Z" fill="#6B2A7E"/>
                    </g>
                    </g>
                    <defs>
                    <clipPath id="clip0_7986_3035">
                    <rect width="42" height="42" fill="white" transform="translate(0 0.180664)"/>
                    </clipPath>
                    </defs>
                    </svg>
                </div>
                <div className='vonciergeHeaderIcon'>
                    <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_7985_3363" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="65">
                    <path d="M7.05654 6.569C0.919143 11.9395 -0.188999 26.2823 0.0241046 32.7824C-0.189003 40.3481 0.983067 55.1597 8.0155 59.6352C15.0479 64.1106 26.449 64.6434 32.3094 64.75C38.7025 64.75 46.0546 64.75 55.6443 59.9549C62.3571 55.1597 63.6357 45.8891 63.9553 32.7824C64.275 19.6757 62.9964 10.7248 56.2836 5.60999C50.9443 1.54169 37.9566 0.601724 32.3094 0.814834C26.449 0.495158 13.1939 1.19845 7.05654 6.569Z" fill="#fff"/>
                    </mask>
                    <g mask="url(#mask0_7985_3363)">
                    <rect width="107.52" height="107.52" transform="translate(-21.7598 -21.0098)" fill="white"/>
                    <rect x="-21.7598" y="-21.0098" width="107.52" height="107.52" fill="white"/>
                    <g clipPath="url(#clip0_7985_3363)">
                    <path d="M32 37.7378C29.2386 37.7378 27 39.9764 27 42.7378V52.7378H37V42.7378C37 39.9764 34.7614 37.7378 32 37.7378Z" fill={headerColor} />
                    <path d="M40.3334 42.7378V52.7378H47C49.7614 52.7378 52 50.4992 52 47.7378V32.5361C52.0004 31.6703 51.6639 30.8383 51.0616 30.2161L36.8984 14.9044C34.3993 12.2005 30.1815 12.0345 27.4776 14.5336C27.3491 14.6523 27.2254 14.776 27.1067 14.9044L12.9684 30.2111C12.3479 30.8358 11.9998 31.6807 12 32.5611V47.7378C12 50.4992 14.2386 52.7378 17 52.7378H23.6666V42.7378C23.6978 38.1931 27.3671 34.4819 31.7973 34.375C36.3758 34.2645 40.2984 38.039 40.3334 42.7378Z" fill={headerColor} />
                    <path d="M32 37.7378C29.2386 37.7378 27 39.9764 27 42.7378V52.7378H37V42.7378C37 39.9764 34.7614 37.7378 32 37.7378Z" fill={headerColor} />
                    </g>
                    </g>
                    <defs>
                    <clipPath id="clip0_7985_3363">
                    <rect width="40" height="40" fill="white" transform="translate(12 12.75)"/>
                    </clipPath>
                    </defs>
                    </svg>
                </div>
            </div>
          )}
        </div>
        ) : (
          <div className='w-full h-[60px] sm:h-[80px] relative header desktop' style={{backgroundColor : `${headerColor}`}}>
            {showBackButton && (
              <div className='cursor-pointer absolute left-4 sm:left-15 xl:left-20 headerBtn headerBackBtn top-[50%] transition-all btnEffect bg-[#FFFFFF] rounded-[67px] flex items-center justify-center gap-[10px]' onClick={handleGoBack}>
                 <div className='changiHeaderIcon'>
                  <svg width="24" height="24" viewBox="0 0 40 40" fill="none">
                      <g clipPath="url(#clip0_6450_3858)">
                      <path d="M20.0209 40C8.96813 39.9117 0.0170743 31.2397 2.46483e-05 20.0101C-0.017025 8.89046 8.81314 -0.017016 20.0473 2.44084e-05C31.111 0.0170648 39.9504 8.79751 40 19.8986C40.0496 31.0616 31.1993 39.8792 20.0225 40H20.0209Z" fill={headerColor} />
                      <path d="M17.2664 19.6902C19.3263 21.732 21.256 23.6172 23.1454 25.5413C23.4756 25.8774 23.753 26.3484 23.8631 26.8022C24.046 27.5567 23.5856 28.3235 22.9083 28.6581C22.1596 29.0268 21.4281 28.923 20.791 28.2941C18.9838 26.5079 17.1904 24.7047 15.3925 22.9093C14.7818 22.3005 14.1634 21.6979 13.5651 21.0782C12.7699 20.2525 12.7575 19.2317 13.5635 18.4199C15.9458 16.0203 18.339 13.6316 20.7368 11.2475C21.5397 10.4497 22.5967 10.4233 23.3485 11.1421C24.1002 11.8609 24.1033 13.0011 23.3035 13.8128C21.6001 15.5432 19.8765 17.2519 18.153 18.9621C17.9127 19.2007 17.6291 19.3959 17.2664 19.6933V19.6902Z" fill="white"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_6450_3858">
                      <rect width="40" height="40" fill="white"/>
                      </clipPath>
                      </defs>
                    </svg>
                 </div>
                 <div className='vonciergeHeaderIcon'>
                    <svg width="24" height="25" viewBox="0 0 40 41" fill="none">
                    <g clipPath="url(#clip0_7985_3383)">
                    <path d="M22.853 39.8609L5.88915 22.897C5.31983 22.3276 5 21.5553 5 20.75C5 19.9447 5.31983 19.1724 5.88915 18.603L22.853 1.63915C23.2777 1.21457 23.8187 0.925442 24.4077 0.808318C24.9967 0.691195 25.6072 0.751332 26.1621 0.981131C26.7169 1.21093 27.1911 1.60007 27.5248 2.09936C27.8585 2.59864 28.0367 3.18566 28.0368 3.7862V37.7138C28.0367 38.3143 27.8585 38.9014 27.5248 39.4006C27.1911 39.8999 26.7169 40.2891 26.1621 40.5189C25.6072 40.7487 24.9967 40.8088 24.4077 40.6917C23.8187 40.5746 23.2777 40.2854 22.853 39.8609Z" fill={headerColor} />
                    </g>
                    <defs>
                    <clipPath id="clip0_7985_3383">
                    <rect width="40" height="40" fill="white" transform="translate(0 0.75)"/>
                    </clipPath>
                    </defs>
                    </svg>
                 </div>
                  <span className='text-[#262626] text-[20px] font-bold'>{t('headings.back')}</span>
              </div>
            )}
            <div className='h-full logoSection'>
              <LogoLight />
            </div>
            {showHomeButton && (
              <div className='cursor-pointer absolute sm:right-15 right-4 xl:right-20 headerBtn top-[50%] transition-all btnEffect bg-[#FFFFFF] rounded-[67px] flex items-center justify-center gap-[10px]' onClick={handleNavigateToHome}>
                  <div className='changiHeaderIcon'>
                    <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0256 5.22675e-07C31.086 0.00249499 40.0075 8.97384 40 20.0879C39.9925 31.0923 30.9962 40.0087 19.9121 40C8.88405 39.9913 -0.0124592 30.9875 1.3098e-05 19.8485C0.0124854 8.92395 9.01875 -0.00249394 20.0256 5.22675e-07ZM10.7349 20.6143C10.7349 20.9248 10.7349 21.1506 10.7349 21.3751C10.7349 23.8546 10.7325 26.3328 10.7362 28.8123C10.7374 29.9635 11.3436 30.5859 12.4973 30.6021C13.5599 30.6171 14.6226 30.6058 15.6852 30.6058C16.0731 30.6058 16.461 30.6058 16.8975 30.6058C16.8975 30.2903 16.8975 30.0633 16.8975 29.8363C16.8975 28.3159 16.8751 26.7943 16.9038 25.2739C16.9387 23.4168 18.2246 22.1197 19.9832 22.1147C21.7493 22.1097 23.0376 23.3869 23.0975 25.239C23.1274 26.1545 23.1038 27.0724 23.1038 27.9891C23.1038 28.8373 23.1038 29.6866 23.1038 30.5722C24.7513 30.5722 26.3303 30.627 27.9019 30.551C28.7799 30.5086 29.2601 29.8288 29.2626 28.9096C29.2676 26.3889 29.2651 23.8683 29.2651 21.3476C29.2651 21.1244 29.2651 20.9011 29.2651 20.6542C29.4958 20.6392 29.6567 20.623 29.8189 20.6193C30.8229 20.5968 31.3629 20.26 31.6248 19.4893C31.878 18.7447 31.6797 18.2196 30.8852 17.5536C27.9829 15.119 25.0794 12.6844 22.1746 10.251C20.8076 9.10605 19.1762 9.11602 17.803 10.2697C16.6244 11.2613 15.4408 12.2478 14.2609 13.2369C12.4748 14.7361 10.6838 16.229 8.9065 17.7381C8.38017 18.1847 8.11576 18.7409 8.3652 19.4556C8.61215 20.1653 9.11728 20.5619 9.8968 20.6118C10.145 20.6267 10.3945 20.6143 10.7349 20.6143Z" fill={headerColor} />
                    <path d="M10.7349 20.6143C10.3944 20.6143 10.1449 20.628 9.89671 20.6118C9.11719 20.5631 8.61206 20.1665 8.36511 19.4556C8.11566 18.7409 8.38132 18.1847 8.90641 17.7382C10.6837 16.229 12.4747 14.7348 14.2608 13.2369C15.4407 12.2466 16.6243 11.26 17.8029 10.2697C19.1761 9.11604 20.8075 9.10606 22.1745 10.251C25.0793 12.6844 27.9828 15.119 30.8851 17.5536C31.6796 18.2196 31.8779 18.7447 31.6248 19.4893C31.3628 20.2613 30.824 20.5968 29.8188 20.6193C29.6566 20.623 29.4945 20.6392 29.265 20.6542C29.265 20.9024 29.265 21.1244 29.265 21.3476C29.265 23.8683 29.2687 26.389 29.2625 28.9096C29.26 29.8276 28.7798 30.5073 27.9018 30.551C26.329 30.6283 24.75 30.5722 23.1037 30.5722C23.1037 29.6866 23.1037 28.8385 23.1037 27.9892C23.1037 27.0724 23.1274 26.1545 23.0974 25.239C23.0376 23.3869 21.7504 22.1097 19.9831 22.1147C18.2245 22.1197 16.9386 23.4168 16.9037 25.2739C16.875 26.7943 16.8974 28.3159 16.8974 29.8363C16.8974 30.0633 16.8974 30.2891 16.8974 30.6059C16.4609 30.6059 16.073 30.6059 15.6851 30.6059C14.6225 30.6059 13.5598 30.6171 12.4972 30.6021C11.3435 30.5859 10.7373 29.9648 10.7361 28.8123C10.7324 26.3328 10.7349 23.8546 10.7349 21.3751C10.7349 21.1493 10.7349 20.9248 10.7349 20.6143Z" fill="white"/>
                    </svg>
                  </div>
                  <div className='vonciergeHeaderIcon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 40 41" fill="none">
                      <g clipPath="url(#clip0_7985_3370)">
                      <path d="M20 25.7378C17.2386 25.7378 15 27.9764 15 30.7378V40.7378H25V30.7378C25 27.9764 22.7614 25.7378 20 25.7378Z" fill={headerColor} />
                      <path d="M28.3334 30.7378V40.7378H35C37.7614 40.7378 40 38.4992 40 35.7378V20.5361C40.0004 19.6703 39.6639 18.8383 39.0616 18.2161L24.8984 2.90443C22.3993 0.200523 18.1815 0.0345078 15.4776 2.53357C15.3491 2.65232 15.2254 2.77599 15.1067 2.90443L0.96836 18.2111C0.347891 18.8358 -0.000234257 19.6807 1.18268e-07 20.5611V35.7378C1.18268e-07 38.4992 2.23859 40.7378 5 40.7378H11.6666V30.7378C11.6978 26.1931 15.3671 22.4819 19.7973 22.375C24.3758 22.2645 28.2984 26.039 28.3334 30.7378Z" fill={headerColor} />
                      <path d="M20 25.7378C17.2386 25.7378 15 27.9764 15 30.7378V40.7378H25V30.7378C25 27.9764 22.7614 25.7378 20 25.7378Z" fill={headerColor} />
                      </g>
                      <defs>
                      <clipPath id="clip0_7985_3370">
                      <rect width="40" height="40" fill="white" transform="translate(0 0.75)"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <span className='text-[#262626] text-[20px] font-bold'>{t('headings.home')}</span>
              </div>
            )}
          </div>
        )
        }
      </>
    );
  };

  export default Header;
