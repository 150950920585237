import { v4 as uuidv4 } from "uuid";


export const getHeaders = () => {
  const deviceType = sessionStorage.getItem('deviceSize') || 'mobile';
  const token = sessionStorage.getItem('token');
  const selLang = sessionStorage.getItem('selectedLanguage');
  const urlToken = getToken();
  const headers = {
    'Content-Type': 'application/json',
    'WEB-TOKEN': getWindowID(),
    'DEVICE-SIZE': deviceType,
    
  };
  if (urlToken) {
    headers['URL-TOKEN'] = urlToken; // Include URL-TOKEN if it exists
  }

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  if (selLang) {
    headers['locale'] = `${selLang}`;
  }

  return headers;
};

export const getWindowID = () => {

  if(!window.name){
      const newUuid = uuidv4();
      window.name = newUuid; 
  }
  return window.name;
};
  export const getToken = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('token'); 
  };
export const getEnableKioskKey = () => {
  const enableWebKioskWithoutToken = sessionStorage.getItem('enable_web_kiosk_without_token')
  if(!enableWebKioskWithoutToken) {
    window.location.replace('/')
  }
}


 export const getVisibility = () => {
  const uniqueToken = getToken();
      function handleVisibilityChange() {
        if (isMobileOrTablet()) {
          if (document.visibilityState == 'visible' || document.visibilityState == 'hidden') {
            window.location.replace(uniqueToken ? `/?token=${uniqueToken}` : '/') 
          }
        }
      }
      function isMobileOrTablet() {
        const userAgent = navigator.userAgent.toLowerCase();
        return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
      }

      document.addEventListener("visibilitychange", handleVisibilityChange);
  
      return () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange);
      };

 }
 export const getVisibilityChange = () => {
  const uniqueToken = getToken(); // Use the helper function
  function handleVisibilityChange() {
    if (isMobileOrTablet()) {
      if (document.visibilityState === 'visible' || document.visibilityState === 'hidden' || document.beforeunload === 'visible' || document.beforeunload === 'hidden' || document.unload === 'visible' || document.unload === 'hidden') {
        window.location.replace(uniqueToken ? `/?token=${uniqueToken}` : '/') 

      }
      if(document.visibilityState === 'visible') {
        window.location.reload();
      }
    }
  }
  function isMobileOrTablet() {
    const userAgent = navigator.userAgent.toLowerCase();
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }
  document.addEventListener("visibilitychange", handleVisibilityChange);
  document.addEventListener("beforeunload", handleVisibilityChange);
  document.addEventListener("unload", handleVisibilityChange);
  return () => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
    document.removeEventListener("beforeunload", handleVisibilityChange);
    document.removeEventListener("unload", handleVisibilityChange);
  };
}

 export const getApiErrorResponse = (response) => {          
      if(response.status == 410) {
        window.location.replace('/error')
      }

 }

 export const redirectHomePage = () => {
  const uniqueToken = getToken(); 
  window.location.replace(uniqueToken ? '/error' : '/') 
 }
 export const redirectFeedbackPage = () => {
  const uniqueToken = getToken(); 
  window.location.replace(uniqueToken ? `/feedback/?token=${uniqueToken}` : '/feedback') 
 }
export const redirectToMaintenance = () => {
  const uniqueToken = getToken(); 
  window.location.replace(uniqueToken ? `/maintenance/?token=${uniqueToken}` : '/maintenance')
}
export const redirectToThankYouPage = () => {
  const uniqueToken = getToken(); 
  window.location.replace(uniqueToken ? `/thankyou/?token=${uniqueToken}` : '/thankyou');
}

export const landscapeMode = () => {
  const handleOrientationChange = () => {
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    const isMobileOrIpad = window.innerWidth < 1024; // Check if width is less than 1024px

    if (isLandscape && isMobileOrIpad) {
      document.body.classList.add("landscapeMode");
    } else {
      document.body.classList.remove("landscapeMode");
    }
  };

  // Add event listeners
  window.addEventListener("resize", handleOrientationChange);
  window.addEventListener("orientationchange", handleOrientationChange);

  // Cleanup event listeners
  return () => {
    window.removeEventListener("resize", handleOrientationChange);
    window.removeEventListener("orientationchange", handleOrientationChange);
  };
}
export const getIOSVersion = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check if the device is iOS
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    const match = userAgent.match(/OS (\d+)_?(\d+)?_?(\d+)?/);
    if (match) {
      const majorVersion = parseInt(match[1], 10);
      const minorVersion = match[2] ? parseInt(match[2], 10) : 0;
      const patchVersion = match[3] ? parseInt(match[3], 10) : 0;

      return {
        major: majorVersion,
        minor: minorVersion,
        patch: patchVersion,
      };
    }
  }

  return null; // Not an iOS device
}
export const handleKeyDown = () => {
  const keyDown = (event) => {
    if (event.ctrlKey && event.key === 'r') {
      event.preventDefault(); 
    }
  };
  window.addEventListener('keydown', keyDown);
  return () => {
    window.removeEventListener('keydown', keyDown);
  };
}