
import React, { Suspense, lazy, useEffect, useState } from 'react';
import './App.css';
import './CompanyCustom.css'
import './font.css';
import './style.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './Pages/i18n';
import { AuthProvider } from './contexts/AuthContext';
import { NetworkStatusProvider } from './contexts/NetworkStatusContext';

import { NavigationProvider } from './contexts/NavigationContext';
import CsatNextLevel from './Pages/Csat/CsatNextLevel';


const DesktopChatBox = lazy(() => import('./Pages/videoCall/Desktop/ChatBox'));
const MobileChatBox = lazy(() => import('./Pages/videoCall/Mobile/ChatBox'));
const Connect = lazy(() => import('./Pages/Connect'));
const SelectLanguage = lazy(() => import('./Pages/SelectLanguage'));
const SelectCategory = lazy(() => import('./Pages/SelectCategory'));
const Waiting = lazy(() => import('./Pages/Waiting'));
const VideoCall = lazy(() => import('././Pages/videoCall/VideoCall'));
const Feedback = lazy(() => import('./Pages/Feedback'));
const Thankyou = lazy(() => import('./Pages/Thankyou'));
const SubCategory = lazy(() => import('./Pages/SubCategory'));
const ActionWeb = lazy(() => import('./Pages/ActionWeb'));
const CallConnect = lazy(() => import('./Pages/CallConnect'));
const Screensaver = lazy(() => import('././Pages/Screensaver'));
const Terms = lazy(() => import('./Pages/Terms'));
const Permissions = lazy(() => import('./Pages/Permission/Permissions'));
const OpenWebView = lazy(() => import('./Pages/OpenWebView')) ;
const ErrorPage = lazy(() => import('./Components/Error/Error')) 
const NotFoundPage = lazy(() => import('./Components/Error/NotFoundPage'))
const MaintainenceScreen = lazy(() => import('./Components/MaintainenceScreen'))
const TextColorWrapper = lazy(() => import('./Components/TextColorWrapper'))

const App = () => {
  
  const [isWebView, setIsWebView] = useState(false);
  const [deviceType, setDeviceType] = useState('');
  const webViewOpen = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const androidWebView = /wv/.test(userAgent) || /Android.*Version\/[\d.]+.*Chrome\/[.0-9]* Mobile/.test(userAgent);
    const iOSWebView = (/iPhone|iPod|iPad/.test(userAgent) && !window.MSStream) &&
        (!window.navigator.standalone && !window.matchMedia('(display-mode: browser)').matches);

    if (androidWebView || iOSWebView) {
        setIsWebView(true);
    }
  }
  const decryptToken = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const encryptedData = queryParams.get('token');
    try {
        const decodedData = atob(encryptedData);
        const data = JSON.parse(decodedData);
        const source = data.source;
        if(source !== 'changiapp') {
          webViewOpen();
        }
        else {
          localStorage.setItem('source', source)
        }
        return data;
      } catch (error) {
        console.log("Decryption failed: " + error.message);
      }
    }
   useEffect(() => {
    decryptToken();
    }, []);
    useEffect(() => {
      const handleResize = () => {
        const isMobile = window.innerWidth <= 600;
        const isTablet = window.innerWidth > 600 && window.innerWidth <= 1025;
        const isDesktop = window.innerWidth > 1025;
        
        if (isMobile) {
          setDeviceType('mobile');
        } else if (isTablet) {
          setDeviceType('tablet');
        } else if (isDesktop) {
          setDeviceType('desktop');
        }
      };
  
      window.addEventListener('resize', handleResize);
      handleResize();  // Initial check on load
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
   
  return (
    
    <div className='homePage'>
      <I18nextProvider i18n={i18n}>
          <NetworkStatusProvider>
              <AuthProvider>
                <Router>
                  <NavigationProvider>
                    <TextColorWrapper> 
                      <Suspense>
                        <Routes>
                        <Route path="/error" element={<ErrorPage />} />
                        <Route path="*" element={<NotFoundPage />} />
                        {isWebView ? (
                            <Route path="/" element={<OpenWebView />} />
                          ) : (
                          <>
                            <Route path="/permissions" element={<Permissions />} />
                            <Route path="/" element={<Screensaver />} />
                            <Route path="/terms" element={<Terms />} />
                            <Route path="/languages" element={<SelectLanguage />} /> 
                            <Route path="/Connect" element={<Connect />} />   
                            <Route path="/:langCode/categories" element={<SelectCategory />} />  
                            <Route path="/:langCode/category/:categoryId/queue" element={<Waiting />} />
                            <Route path="/call" element={<VideoCall />} />  
                            <Route path="/desktop-chatbox" element={<DesktopChatBox />} />
                            <Route path="/mobile-chatbox" element={<MobileChatBox />} />
                            <Route path="/feedback" element={<Feedback />} />
                            <Route path="/thankyou" element={<Thankyou />} />
                            <Route path="/:langCode/category/:categoryId/subCategories" element={<SubCategory />} />
                            <Route path="/webView" element={<ActionWeb />} />
                            <Route path="/callConnect" element={<CallConnect />} />
                            <Route path="/maintenance" element={<MaintainenceScreen />} />
                            <Route path="/csat" element={<CsatNextLevel />} />
                            
                          </>
                          )}
                        </Routes>
                      </Suspense>
                    </TextColorWrapper>
                  </NavigationProvider>
                </Router>
              </AuthProvider>
          </NetworkStatusProvider>
      </I18nextProvider>
    </div>
  );
};



export default App;
